import { useEffect, useState } from 'react'
import useSound from 'use-sound'
import { styled } from 'linaria/react'
import { isMobile } from 'react-device-detect'
import { AppContext } from 'contexts/AppContext'
import { Intro } from 'components/screens/Intro'
import { Game } from 'components/screens/Game'
import { GameOver } from 'components/screens/GameOver'
import { MobileHelp } from 'components/screens/MobileHelp'
import { SoundButton } from 'components/SoundButton'
import usePrevious from 'hooks/usePrevious'
import theme from 'theme/theme'
import startSound from 'sounds/start.mp3'
import gameOverSound from 'sounds/gameOver.mp3'

const Page = styled.div`
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${theme.colors.gameOverBackground};
`

const ScrollingScreen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: transform 3s;

  &.Game {
    transform: translateY(calc(-50% - 300px));
  }
`

const Clouds = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../theme/intro-clouds-top.png) no-repeat center 0 / 100% auto,
    url(../theme/intro-clouds-bottom.png) no-repeat 0 center / 100% auto;
  pointer-events: none;
`

const App = () => {
  const [audio, setAudio] = useState(true)
  const [screen, setScreen] = useState(() => (isMobile ? 'MobileHelp' : 'Intro'))
  const prevScreen = usePrevious(screen)
  const [score, setScore] = useState(0)
  const [screenHeight, setScreenHeight] = useState()
  const [playStart] = useSound(startSound, { soundEnabled: audio })
  const [playGameOver] = useSound(gameOverSound, { soundEnabled: audio })

  useEffect(() => {
    if (screen === 'Game' && (prevScreen === 'Intro' || prevScreen === 'GameOver')) {
      playStart()
    } else if (screen === 'GameOver' && prevScreen === 'Game') {
      playGameOver()
    }
  }, [screen, prevScreen, playStart, playGameOver])

  useEffect(() => {
    const handleResize = () => setScreenHeight(document.documentElement.clientHeight * 2)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AppContext.Provider
      value={{ isMobile, audio, setAudio, screen, setScreen, score, setScore }}
    >
      <Page>
        {screen === 'MobileHelp' && <MobileHelp />}
        {(screen === 'Intro' || screen === 'Game') && (
          <ScrollingScreen className={screen} style={{ height: screenHeight }}>
            <Intro />
            <Game started={screen === 'Game'} />
            <Clouds />
          </ScrollingScreen>
        )}
        {screen === 'GameOver' && <GameOver />}
        {screen !== 'MobileHelp' && <SoundButton />}
      </Page>
    </AppContext.Provider>
  )
}

export default App
