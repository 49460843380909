import { useContext, useEffect, useState } from 'react'
import useSound from 'use-sound'
import { AppContext } from 'contexts/AppContext'
import randomInt from 'utils/randomInt'
import useTimeout from 'hooks/useTimeout'
import image from './scoreSheep.png'
import sound from 'sounds/sheep.m4a'

const width = 355

export const ScoreSheep = ({ destroy }) => {
  useTimeout(destroy, 2000)

  const [side] = useState(() => ['top', 'right', 'bottom', 'left'][randomInt(0, 3)])
  const [style] = useState(() => {
    let transform
    let transformOrigin
    let top
    let right
    let bottom
    let left
    if (side === 'top') {
      transform = 'rotate(180deg)'
      top = 0
      left = randomInt(-width / 2, document.documentElement.clientWidth - width)
    } else if (side === 'right') {
      transform = 'rotate(-90deg)'
      transformOrigin = 'bottom right'
      right = 0
      bottom = randomInt(width, document.documentElement.clientHeight + width / 2)
    } else if (side === 'bottom') {
      bottom = 0
      left = randomInt(0, document.documentElement.clientWidth - width / 2)
    } else if (side === 'left') {
      transform = 'rotate(90deg)'
      transformOrigin = 'bottom left'
      left = 0
      bottom = randomInt(width / 2, document.documentElement.clientHeight)
    }
    return {
      display: 'block',
      position: 'absolute',
      width,
      transform,
      transformOrigin,
      top,
      right,
      bottom,
      left,
    }
  })
  const { audio } = useContext(AppContext)
  const [play] = useSound(sound, { soundEnabled: audio })

  useEffect(() => play(), [play])

  return <img src={image} alt="Score Sheep!" style={style} />
}
